import React, { Component } from 'react'
import * as ApiFunctions from '../../util/apifunction'
import { Route , withRouter} from 'react-router-dom';

import UserContext from '../ContextManage/UserContext'

import {Grid,Typography,InputLabel,MenuItem,Checkbox,Button,Select,FormControl,OutlinedInput,Box} from '@material-ui/core'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const LoanAmounts=[3000,5000,7000,8000,9000,10000,11000,12000,13000,14000,15000,16000,17000,18000,19000,20000]
const LoanTenures=[1,2,3,4,5,6,7,8,9,10,11,12];
const LoanRoi=[1.00,1.50,2.00,2.50,3.00,3.50,4.00,4.50,5.00,5.50,6.00];

class LoanProfile extends Component {
    static contextType =UserContext;
constructor(props){
    
    super(props);
    this.state={
        LoanAmount:[],
        LoanTenure:[],
        SelectedAmount:[],
        SelectedTenure:[],
        LoanRoi:4.00,
        ID:null,
        Email:null,
        Role:null,
        Token:null,
        isloggedin:false,
    }
}


async componentDidMount(){

    const UserContextData = this.context
    await this.setState({
      ID:UserContextData.state.ID,
      Email:UserContextData.state.Email,
      isloggedin:UserContextData.state.loggedin,
      Token:UserContextData.state.Token,
      Role:UserContextData.state.Role
     })

     await this.GetLoanProfile()
}



async GetLoanProfile() {
    const header = {
      'Content-Type': 'application/json',
      'x-access-token': this.state.Token
    };
    try {
      const Data = await ApiFunctions.GetApiRequest(`/loan/profile/loan-profile/${this.props.UID}`, header)

      this.setState({
        LoanAmount: JSON.parse(Data.profile["LoanAmount"]),

        LoanTenure: JSON.parse(Data.profile["LoanTenure"]),
        LoanRoi: parseFloat(Data.profile["LoanRoi"])
      })

      console.log("Data Lona Profile",Data)
    } catch (err) {
      this.setState({ isError: true })
    }

  }


  
async UpdateLoanProfile() {
    const header = {
      'Content-Type': 'application/json',
      'x-access-token': this.state.Token
    };

    const data={
        LoanAmount:this.state.LoanAmount,
        LoanTenure:this.state.LoanTenure,
        LoanRoi:this.state.LoanRoi
    }
    try {
      const Data = await ApiFunctions.PostApiData(`/loan/profile/loan-profile/${this.props.UID}`, header,data)
        console.log(Data)
    } catch (err) {
      this.setState({ isError: true })
    }

  }



  async  handleLoanAmount(event){
    await  this.setState({LoanAmount:event.target.value})

    console.log(this.state.LoanAmount)
   }


   
  async  handleLoanTenure(event){
    await  this.setState({LoanTenure:event.target.value})
    console.log(this.state.LoanTenure)
   }

   async  handleLoanRoi(event){
    await  this.setState({LoanRoi:event.target.value});
    console.log(this.state.LoanRoi);
   }


    render() {
        return (
            <div>
                    <Typography variant="h6" color="initial">Loan Profile</Typography>


                    <Box m={2}/>

                <Grid container spacing={2}>
                  
                    <Grid item lg={12}>

                    <FormControl sx={{ m: 1, width: 400 }}>
        <InputLabel id="loan-amount-selector">Amount</InputLabel>
        <Select
          labelId="loan selector"
          id="loan-selector"
          multiple
          value={this.state.LoanAmount}
                onChange={(event)=>this.handleLoanAmount(event)}
          input={<OutlinedInput label="Amount" />}
         MenuProps={MenuProps}
        >
          {LoanAmounts.map((name) => (
            <MenuItem
              key={name}
              value={name}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

                    </Grid>



                    <Grid item lg={12}>


       <FormControl sx={{ m: 1, width: 400 }}>
        <InputLabel id="loan-amount-selector">Tenure</InputLabel>
        <Select
          labelId="loan selector"
          id="loan-selector"
          multiple
          value={this.state.LoanTenure}
                onChange={(event)=>this.handleLoanTenure(event)}
          input={<OutlinedInput label="Amount" />}
         MenuProps={MenuProps}
        >
          {LoanTenures.map((name) => (
            <MenuItem
              key={name}
              value={name}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      </Grid>

      <Grid item lg={12}>

      <FormControl sx={{ m: 1, width:"100%" }}>
        <InputLabel id="loan-amount-selector">ROI</InputLabel>
        <Select
          labelId="loan-interest"
          id="loan-intrest"
          value={this.state.LoanRoi}
                onChange={(event)=>this.handleLoanRoi(event)}
          input={<OutlinedInput label="ROI" />}
        >
          {LoanRoi.map((name) => (
            <MenuItem
              key={name}
              value={name}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>


                    </Grid>
                </Grid>

                <Box m={2}/>
                    <Button variant="outlined" size="large" color="default" onClick={()=>this.UpdateLoanProfile()}>
                      SAVE
                    </Button>


                    <Button variant="text" color="secondary"onClick={()=>this.props.history.push(`/app/user-contact-profile/${this.props.UID}`)}>
                      View Contacts
                    </Button>

                    <Button variant="contained" color="primary"onClick={()=>this.props.history.push(`/app/user-location-profile/${this.props.UID}`)}>
                      View Location Detail
                    </Button>
            </div>
        )
    }
}

export default withRouter(LoanProfile);
